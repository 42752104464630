<template>
  <router-view></router-view>
</template>

<script>
const keyList = ["smoke", "water", "power", "monitor", "traffic", "plaque", "massif", "meteorology", "car"];

export default {
  name: "SafeTemplete",
  data() {
    return {};
  },
  watch: {
    $route() {
      this.getSafeInfoMenu();
    },
  },
  created() {
    this.getSafeInfoMenu();
  },
  beforeRouteLeave(to, from, next) {
    // 跳转到不是模板子菜单下面的页面 清空子菜单
    // console.log("to:", to);
    if (!(keyList.indexOf(to.meta.key) >= 0)) {
      this.$store.commit("setSubMenus", null);
      this.$store.commit("setTemp", null);
    }
    next();
  },
  methods: {
    getSafeInfoMenu() {
      const { meta } = this.$route;
      const key = meta.key;
      this.getInfo(key);
      this.getSafeMenu(key);
    },
    getInfo(key) {
      if (key == "car") {
        this.$axios({
          url: "/admin/apply-temp?filter[type]=13",
          method: "GET",
        }).then((res) => {
          let info = res.data[0];
          this.$store.commit("setTemp", info);
        });
      }
    },
    getSafeMenu(key) {
      let menu = [];
      if (key == "car") {
        menu = [
          {
            title: "车辆管理",
            path: `/safe/${key}/list`,
          },
          {
            title: "数据统计",
            path: `/safe/${key}/data`,
          },
          {
            title: "模板设置",
            path: `/safe/${key}/form`,
          },
          {
            title: "规则权限",
            path: `/safe/${key}/setting`,
          },
          {
            title: "车牌下发",
            path: `/safe/${key}/plate`,
          },
        ];
      } else {
        menu = [
          {
            title: "数据统计",
            path: `/safe/${key}/data`,
          },
          {
            title: "设备列表",
            path: `/safe/${key}/list`,
          },
          {
            title: "预警管理",
            path: `/safe/${key}/record`,
          },
          {
            title: "预警事件处理",
            path: `/safe/${key}/work`,
          },
        ];
        if (key == "traffic") {
          menu.pop();
        } else if (key == "meteorology") {
          menu.pop();
          menu.splice(1, 1);
        }
      }

      this.$store.commit("setSafeRoute", this.$route);
      this.$store.commit("setSubMenus", menu);
    },
  },
};
</script>
